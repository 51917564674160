import { ARROW_POSITIONS, ArrowPosition } from 'constants/generic';
import styled, { css } from 'styled-components';
import { breakpointUp, color, spacer } from 'theme';
import { Breakpoint } from 'types/Breakpoint';
import CarouselArrow from '../CarouselArrow/CarouselArrow';

const responsiveShowHideElements = (responsiveObject: ShowArrowsObject) => {
  const breakpoints = Object.entries(responsiveObject);

  if (breakpoints.length === 0) {
    return;
  }

  return breakpoints.map(([breakpoint, isVisible]) =>
    breakpointUp(
      breakpoint,
      css`
        display: ${isVisible ? 'flex' : 'none'};
      `,
    ),
  );
};

export type SlidesObject = {
  [key in Breakpoint]?: number | 'auto';
};

export type ShowArrowsObject = Partial<Record<Breakpoint | 'default', boolean>>;

export interface StyledWrapperProps {
  arrowPosition?: ArrowPosition;
  hasTitle?: boolean;
  itemGap?: number;
  showArrows?: ShowArrowsObject;
  showArrowsOnHover?: boolean;
  showDots?: object;
  slidesToShow?: SlidesObject;
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  position: relative;

  .embla {
    width: 100%;

    ${({ arrowPosition }) =>
      arrowPosition === ARROW_POSITIONS.CENTER &&
      css`
        .embla__button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &__prev {
            left: ${spacer(150)};
          }

          &__next {
            right: ${spacer(150)};
          }
        }
      `};

    ${({ showArrowsOnHover }) =>
      showArrowsOnHover &&
      css`
        .embla__button {
          display: none;
        }

        &:hover {
          .embla__button {
            display: flex;
          }
        }
      `}
  }

  .embla__viewport {
    overflow: hidden;
    margin-left: ${({ arrowPosition, showArrows }) =>
      arrowPosition === ARROW_POSITIONS.CENTER && showArrows?.['default'] && spacer(450)};
  }

  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    ${({ itemGap = 0 }) => css`
      margin-left: -${itemGap / 100}rem;
    `};
  }

  .embla__slide {
    min-width: 0;
    position: relative;
    ${({ itemGap = 0 }) => css`
      padding-left: ${itemGap / 100}rem;
    `};
    ${({ slidesToShow = {} }) =>
      Object.entries(slidesToShow).map(([breakpoint, value]) => {
        if (value === 'auto') {
          return breakpointUp(
            breakpoint,
            css`
              flex: 0 0 auto;
            `,
          );
        }
        if (isNaN(value)) return;
        return breakpointUp(
          breakpoint,
          css`
            flex: 0 0 ${`calc(100% / ${value})`};
          `,
        );
      })};
  }
`;

export const StyledDotsWrapper = styled.ul<StyledWrapperProps>`
  & {
    ${({ showDots }) => showDots && responsiveShowHideElements(showDots)}
  }

  display: none;
  margin-top: ${spacer(100)};
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${spacer(50)};
  list-style-type: none;
  bottom: 0px;
`;

export const StyledDot = styled.li<{ active?: boolean }>`
  border: 1px solid ${({ active }) => (active ? 'none' : color('whisper-300'))};
  background-color: ${({ active }) => (active ? color('primary-400') : 'none')};
  border-radius: 50%;
  list-style-type: none;
  width: ${({ active }) => (active ? spacer(100) : spacer(75))};
  height: ${({ active }) => (active ? spacer(100) : spacer(75))};
`;

export const StyledArrowsWrapper = styled.div<StyledWrapperProps>`
  display: none;

  & {
    ${({ showArrows }) => showArrows && responsiveShowHideElements(showArrows)}
  }
  gap: ${spacer(50)};
`;

export const StyledArrow = styled(CarouselArrow)<StyledWrapperProps>`
  display: none;
  & {
    ${({ showArrows }) => showArrows && responsiveShowHideElements(showArrows)}
  }
`;
